import custodyOrganization from './custodyOrganization';
import withdrawExploer from './withdrawExploer';
import mpcWithdrawExploer from './mpcWithdrawExploer';
import suspendedManagement from './suspendedManagement';
import threePartyHostingApplication from './threePartyHostingApplication';
import threePartyHostingList from './threePartyHostingList';
import superloop from './superloop';
import superloopExchange from './superloopExchange';
import tightenRiskControl from './tightenRiskControl';
import coinManage from './coinManage';
import mpcKyt from './mpckyt';
import travelRuleInboundRefund from './travelRuleInboundRefund';
import addCoin from './addCoin';
import withdrawExplorerReborn from './withdrawExplorerReborn';

const custody = {
    ...custodyOrganization,
    ...withdrawExploer,
    ...mpcWithdrawExploer,
    ...suspendedManagement,
    ...threePartyHostingApplication,
    ...threePartyHostingList,
    ...superloop,
    ...superloopExchange,
    ...tightenRiskControl,
    ...coinManage,
    ...mpcKyt,
    ...travelRuleInboundRefund,
    ...addCoin,
    ...withdrawExplorerReborn,
};

export default custody;
