import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
// import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
const BreadcrumbComponent = ({ menu, activeKey, currentTab }) => {
    const [items, setItems] = useState([]);
    const flattenRoutes = (arr) =>
        arr.reduce(function (prev, item) {
            const { children, ...rest } = item;
            prev.push(rest);
            if (Array.isArray(children)) {
                prev = prev.concat(flattenRoutes(children));
            }
            return prev;
        }, []);
    const flattenMenu = flattenRoutes(menu);
    const getBreadcrumbs = ({ flattenRoutes, location }) => {
        let breadCrumb = [];
        const pathname = window.location.hash.slice(1).split('?')[0];
        const pathList = pathname.split('/');
        let tmpList = [...pathList];
        let i = 0;
        while (i < tmpList.length) {
            let curPath = tmpList.slice(0, i + 1).join('/');
            let obj = flattenRoutes.find((item) => {
                return item.path === curPath || new RegExp(`^${item.path}$`, 'g').test(curPath);
            });
            if (obj) {
                breadCrumb.push(obj);
            }
            i++;
        }
        const items = breadCrumb
            .filter((item) => item.key)
            .map((item, index) => {
                return {
                    title:
                        index !== 0 ? (
                            <Link to={index === breadCrumb.length - 1 ? window.location.hash.slice(1) : item.path}>
                                {<FormattedMessage id={item.key} />}
                            </Link>
                        ) : (
                            <FormattedMessage id={item.key} />
                        ),
                };
            });
        setItems(items);
    };

    useEffect(() => {
        getBreadcrumbs({ flattenRoutes: flattenMenu, location: window.location });
    }, [currentTab]);
    return (
        <Breadcrumb
            style={{ marginTop: 14, paddingLeft: 10 }}
            items={[
                {
                    title: 'Home',
                },
                ...items,
            ]}
        />
    );
};
const mapStateToProps = (state) => ({
    menu: state.common.menu,
    activeKey: state.common.activeKey,
    currentTab: state.common.currentTab,
});

export default connect(mapStateToProps)(BreadcrumbComponent);
