const kyt = {
    // common
    'kyt.strategyName': 'Strategy Name',
    'kyt.placeholder.default': 'Default All {name}',
    'kyt.ok.btn': 'Yes',

    // filter area
    'kyt.routeConfiguration.filter.organization': 'Organization',
    'kyt.routeConfiguration.filter.vendor': 'Vendor',
    'kyt.routeConfiguration.add': 'Add Strategy',
    'kyt.routeConfiguration.copy': 'Copy',
    'kyt.filter.coin': 'Token',
    'kyt.filter.auditType': 'Audit Type',
    'kyt.filter.timeRange': 'Time Range',

    // table
    'kyt.table.col.organization': 'Organization',
    'kyt.table.col.transactionType': 'Transaction Type',
    'kyt.table.col.amount': 'Amount(Amount Predicate)',
    'kyt.table.col.updated_at': 'Updated Time',
    'kyt.table.col.created_at': 'Created Time',
    'kyt.table.col.updated_by': 'Updated By',
    'kyt.table.col.action': 'Actions',
    'kyt.table.btn.activate': 'Activate',
    'kyt.table.btn.deactivate': 'Deactivate',
    'kyt.table.col.routeRule': 'Route Rule',
    'kyt.table.col.auditType': 'Audit Type',
    'kyt.table.btn.auditResponse': 'Response Details',
    'kyt.table.btn.auditRequest': 'Request Details',

    // modal
    'kyt.routeConfiguration.modal.title': 'Routing Configuration Rules',
    'kyt.routeConfiguration.modal.id': 'Strategy ID',
    'kyt.routeConfiguration.modal.org_id': 'Org ID',
    'kyt.routeConfiguration.modal.updated_by': 'Modified By',
    'kyt.routeConfiguration.modal.amount_filter': 'Amount',
    'kyt.routeConfiguration.modal.status': 'Status',
    'kyt.routeConfiguration.modal.audit_type': 'Transaction Type',
    'kyt.routeConfiguration.modal.asset_id': 'Token',
    'kyt.routeConfiguration.modal.date': 'Date',
    'kyt.routeConfiguration.modal.fallback_vendor': 'Vendor',
    'kyt.routeConfiguration.modal.primary_vendor': 'Backup Vendor',
    'kyt.routeConfiguration.modal.updated_at': 'Modified Date',
    'kyt.routeConfiguration.edit.modal.title': 'Edit Strategy',
    'kyt.routeConfiguration.copy.modal.title': 'Copy Strategy',
    'kyt.routeConfiguration.new.modal.title': 'Add Strategy',
    'kyt.routeConfiguration.edit.modal.amount': 'Amount',
    'kyt.routeConfiguration.edit.modal.amountMode': 'Amount Predicate',
    'kyt.routeConfiguration.edit.modal.strategyStatus': 'Strategy Status',
    'kyt.routeConfiguration.edit.modal.deactivate.content': 'Are you sure to deactivate?',
    'kyt.routeConfiguration.edit.modal.activate.content': 'Are you sure to activate?',
    'kyt.routeConfiguration.edit.modal.vendor': 'Vendor',
    'kyt.routeConfiguration.edit.modal.backupVendor': 'Backup Vendor',

    // amount predicate
    'kyt.amountPredicate.any': 'Any Amount',
    'kyt.amountPredicate.gt': 'Greater Than',
    'kyt.amountPredicate.gte': 'Greater than or equal to',
    'kyt.amountPredicate.lt': 'Less Than',
    'kyt.amountPredicate.lte': 'Less Than or equal to',
    'kyt.amountPredicate.range': 'Closed Range',
    'kyt.amountPredicate.rangeRightExclusive': 'Right Exclusive Range',

    //task lists
    'kyt.taskList.taskID': 'Task ID',
    'kyt.taskList.filter.taskID.placeholder': 'Please Enter Task ID',
    'kyt.taskList.filter.transactionID': 'Transaction ID',
    'kyt.taskList.filter.transactionID.placeholder': 'Please Enter Transaction ID',
    'kyt.taskList.strategyID': 'Strategy ID',
    'kyt.taskList.filter.strategyID.placeholder': 'Please Enter Strategy ID',

    //task lists table
    'kyt.taskList.table.col.vendorRequestID': 'Vendor Request ID',
    'kyt.taskList.table.col.startTime': 'Start Time',
    'kyt.taskList.table.col.strategyName': 'Strategy Name',

    'kyt.form.error.msg': '{name} is required',
    'kyt.more.riskDetails.tip': 'For more risk details, refer to the Audit Response JSON.',
    'kyt.log.title': 'Route Session Log',
};

export default kyt;
