const kyt = {
    //common
    'kyt.strategyName': '策略名称',
    'kyt.placeholder.default': '默认选择全部{name}',
    'kyt.ok.btn': '确认',

    // filter area
    'kyt.routeConfiguration.filter.organization': '机构',
    'kyt.routeConfiguration.filter.vendor': '供应商',
    'kyt.routeConfiguration.add': '新增策略',
    'kyt.routeConfiguration.copy': '复制',
    'kyt.filter.coin': '币种',
    'kyt.filter.auditType': '审查类型',
    'kyt.filter.timeRange': '时间范围',

    // table
    'kyt.table.col.organization': '机构',
    'kyt.table.col.transactionType': '交易类型',
    'kyt.table.col.amount': '金额(金额范围谓词)',
    'kyt.table.col.updated_at': '更新日期',
    'kyt.table.col.created_at': '创建时间',
    'kyt.table.col.updated_by': '更新者',
    'kyt.table.col.action': '操作',
    'kyt.table.btn.activate': '启用',
    'kyt.table.btn.deactivate': '禁用',
    'kyt.table.col.routeRule': '路由规则',
    'kyt.table.col.auditType': '审查类型',
    'kyt.table.btn.auditResponse': '响应详情',
    'kyt.table.btn.auditRequest': '请求详情',

    // modal
    'kyt.routeConfiguration.modal.title': '路由配置规则',
    'kyt.routeConfiguration.modal.id': '策略ID',
    'kyt.routeConfiguration.modal.org_id': '机构ID',
    'kyt.routeConfiguration.modal.updated_by': '修改人',
    'kyt.routeConfiguration.modal.amount_filter': '金额',
    'kyt.routeConfiguration.modal.asset_id': '币种',
    'kyt.routeConfiguration.modal.status': '状态',
    'kyt.routeConfiguration.modal.audit_type': '交易类型',
    'kyt.routeConfiguration.modal.date': '时间',
    'kyt.routeConfiguration.modal.fallback_vendor': '备用供应商',
    'kyt.routeConfiguration.modal.primary_vendor': '主要供应商',
    'kyt.routeConfiguration.modal.updated_at': '修改时间',
    'kyt.routeConfiguration.edit.modal.title': '编辑策略',
    'kyt.routeConfiguration.copy.modal.title': '复制策略',
    'kyt.routeConfiguration.new.modal.title': '新增策略',
    'kyt.routeConfiguration.edit.modal.amount': '金额',
    'kyt.routeConfiguration.edit.modal.amountMode': '金额范围谓词',
    'kyt.routeConfiguration.edit.modal.strategyStatus': '策略状态',
    'kyt.routeConfiguration.edit.modal.deactivate.content': '确定要禁用吗?',
    'kyt.routeConfiguration.edit.modal.activate.content': '确定要启用吗?',
    'kyt.routeConfiguration.edit.modal.vendor': '主要供应商',
    'kyt.routeConfiguration.edit.modal.backupVendor': '备用供应商',

    // amount predicate
    'kyt.amountPredicate.any': '任意值',
    'kyt.amountPredicate.gt': '大于',
    'kyt.amountPredicate.gte': '大于等于',
    'kyt.amountPredicate.lt': '小于',
    'kyt.amountPredicate.lte': '小于等于',
    'kyt.amountPredicate.range': '左右包含区间',
    'kyt.amountPredicate.rangeRightExclusive': '不包含右侧区间',

    // task lists
    'kyt.taskList.taskID': '任务ID',
    'kyt.taskList.filter.taskID.placeholder': '请输入任务ID',
    'kyt.taskList.filter.transactionID': '交易ID',
    'kyt.taskList.filter.transactionID.placeholder': '请输入交易ID',
    'kyt.taskList.strategyID': '策略ID',
    'kyt.taskList.filter.strategyID.placeholder': '请输入策略ID',

    // task lists table
    'kyt.taskList.table.col.vendorRequestID': 'Vendor请求ID',
    'kyt.taskList.table.col.startTime': '发起时间',
    'kyt.taskList.table.col.strategyName': '策略名称',

    'kyt.form.error.msg': '{name}为必填项',
    'kyt.more.riskDetails.tip': '更多风险详情请查看Audit Response JSON',
    'kyt.log.title': '路由日志',
};

export default kyt;
