const manualInbound = {
    'blockchain.manualInbound': 'Refund Supplementary Entry',
    'blockchain.manualInbound.table.head.txid': 'RefundsTxid',
    'blockchain.manualInbound.table.label.assetCoin': 'Asset Coin',
    'blockchain.manualInbound.table.head.custodyVisible': 'Custody Visible',
    'blockchain.manualInbound.table.head.watchOnly': 'WatchOnly Visible',
    'blockchain.manualInbound.table.head.withdraw_txid': 'Txid',
    'blockchain.manualInbound.table.head.contractAddress': 'Contract Address',
    'blockchain.manualInbound.table.head.isActive': 'Is Active',

    'blockchain.manualInbound.table.head.orgId': 'Org ID',
    'blockchain.manualInbound.table.head.orgName': 'Org name',
    'blockchain.manualInbound.table.head.Result': 'Result',

    'blockchain.manualInbound.formItem.label.refundsAddress': 'Refunds Address',
    'blockchain.manualInbound.formItem.label.inboundAddress': 'Inbound Address',
    'blockchain.manualInbound.formItem.label.inboundAddressMemo': 'Inbound Address memo',
    'blockchain.manualInbound.formItem.label.amount': 'Amount',
    'blockchain.manualInbound.formItem.label.phaTaskId': 'Phabricator Task',
    'blockchain.manualInbound.formItem.label.note': 'Note',
    'blockchain.manualInbound.formItem.label.chainCoin': 'Chain_coin',
    'blockchain.placeholder.addToken': 'Default All Coins',
    'blockchain.modal.tokenInfoCheck.title': 'Token Info Search',
    'blockchain.modal.tokenInfoCheck.tokenAddress': 'Token Address',
    'blockchain.modal.tokenInfoCheck.btn': 'Token Address Check',
    'blockchain.modal.tokenInfoCheck.error.title': 'Warning',
    'blockchain.modal.tokenInfoCheck.error.content': 'No more information for this token!',
    'blockchain.modal.tokenInfoCheck.tokenInfo': 'Token Details',

    'blockchain.form.required': 'Required',
    'blockchain.form.btn.addToken': 'Add Token',
    'blockchain.form.success.add': 'Add Success',
    'blockchain.form.success.edit': 'Edit Success',
    'blockchain.form.coinRisk': 'Coin Risk',
    'blockchain.form.coinRiskMark': 'Coin Risk Mark',

    'blockchain.logModal.expand.text': 'Click on the left to expand and view more information.',
};
export default manualInbound;
