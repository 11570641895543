const addCoin = {
    'custody.addCoin.siderBar.title': '用户上币申请',

    'custody.addCoin.filterLabel.requestId': '申请 ID',
    'custody.addCoin.filterLabel.orgUuid': '机构 uuid',
    'custody.addCoin.filterLabel.walletType': '钱包类型',
    'custody.addCoin.filterLabel.chain': '所属链',
    'custody.addCoin.filterLabel.contractAddress': '合约地址',
    'custody.addCoin.filterLabel.approvalStatus': '审核状态',
    'custody.addCoin.filterLabel.createdTime': '创建时间',
    'custody.addCoin.filterLabel.orgIdAndName': '机构ID/名称',

    'custody.addCoin.col.orgName': '机构名称',
    'custody.addCoin.col.applicant': '申请人',
    'custody.addCoin.col.updatedTime': '更新时间',
    'custody.addCoin.col.before': '异动前',
    'custody.addCoin.col.after': '异动后',
    'custody.addCoin.col.operator': '操作人',
    'custody.addCoin.col.operationTime': '操作时间',
    'custody.addCoin.col.comment': '备注',

    'custody.addCoin.placeholder.requestId': '请输入申请 id',
    'custody.addCoin.placeholder.orgUuid': '请输入机构 uuid',
    'custody.addCoin.placeholder.walletType': '请选择钱包类型',
    'custody.addCoin.placeholder.chain': '请选择所属链',
    'custody.addCoin.placeholder.contractAddress': '请输入合约地址',
    'custody.addCoin.placeholder.approvalStatus': '请选择审核状态',
    'custody.addCoin.placeholder.createdTime': '请选择创建时间',
    'custody.addCoin.placeholder.ticketId': '请输入 Ticket ID',
    'custody.addCoin.placeholder.orgIdAndName': '请输入机构ID或名称',

    'custody.addCoin.log.modal.title': '日志 ({request_id})',
    'custody.addCoin.log': '日志',
    'custody.addCoin.audit.modal.title.approve': '审核通过 ({request_id})',
    'custody.addCoin.audit.modal.approve': '审核通过',
    'custody.addCoin.audit.modal.title.reject': '审核拒绝 ({request_id})',
    'custody.addCoin.audit.modal.reject': '审核拒绝',
    'custody.addCoin.audit.modal.title.start': '开始审核 ({request_id})',
    'custody.addCoin.audit.modal.start': '开始审核',
    'custody.addCoin.audit.modal.start.content': '确认开始审核？',
    'custody.addCoin.audit.modal.reject.content': '此操作将通知用户申请上币拒绝，确认审核拒绝？',
    'custody.addCoin.audit.modal.approve.content': '此操作将通知用户申请上币成功，确认审核通过？',
};

export default addCoin;
