const addCoin = {
    'custody.addCoin.siderBar.title': 'Coin Listing Request',

    'custody.addCoin.filterLabel.requestId': 'Request ID',
    'custody.addCoin.filterLabel.orgUuid': 'Org uuid',
    'custody.addCoin.filterLabel.walletType': 'Wallet Type',
    'custody.addCoin.filterLabel.chain': 'Chain Coin',
    'custody.addCoin.filterLabel.contractAddress': 'Contract Address',
    'custody.addCoin.filterLabel.approvalStatus': 'Approval Status',
    'custody.addCoin.filterLabel.createdTime': 'Created Time',
    'custody.addCoin.filterLabel.orgIdAndName': 'Org ID/Name',

    'custody.addCoin.col.orgName': 'Org Name',
    'custody.addCoin.col.applicant': 'Applicant',
    'custody.addCoin.col.updatedTime': 'Updated Time',
    'custody.addCoin.col.before': 'Before The Abnormal Change',
    'custody.addCoin.col.after': 'After The Abnormal Change',
    'custody.addCoin.col.operator': 'Operator',
    'custody.addCoin.col.operationTime': 'Operation Time',
    'custody.addCoin.col.comment': 'Comment',

    'custody.addCoin.placeholder.requestId': 'Please enter request id',
    'custody.addCoin.placeholder.orgUuid': 'Please enter org uuid',
    'custody.addCoin.placeholder.walletType': 'Please select Wallet Type',
    'custody.addCoin.placeholder.chain': 'Please select Chain Coin',
    'custody.addCoin.placeholder.contractAddress': 'Please enter contract address',
    'custody.addCoin.placeholder.approvalStatus': 'Please select approval status',
    'custody.addCoin.placeholder.createdTime': 'Please select created time',
    'custody.addCoin.placeholder.ticketId': 'Please enter Ticket ID',
    'custody.addCoin.placeholder.orgIdAndName': 'Please enter org ID or name',

    'custody.addCoin.log.modal.title': 'Log ({request_id})',
    'custody.addCoin.log': 'Log',
    'custody.addCoin.audit.modal.title.approve': 'Approve Audit ({request_id})',
    'custody.addCoin.audit.modal.approve': 'Approve Audit',
    'custody.addCoin.audit.modal.title.reject': 'Reject Audit ({request_id})',
    'custody.addCoin.audit.modal.reject': 'Reject Audit',
    'custody.addCoin.audit.modal.title.start': 'Start Audit ({request_id})',
    'custody.addCoin.audit.modal.start': 'Start Audit',
    'custody.addCoin.audit.modal.start.content': 'Confirm to start the audit?',
    'custody.addCoin.audit.modal.reject.content':
        'This action will inform the user that their coin listing application has been rejected. Confirm rejection?',
    'custody.addCoin.audit.modal.approve.content':
        'This action will inform the user that their coin listing request has been approved. Confirm approval?',
};

export default addCoin;
