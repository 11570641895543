const manualInbound = {
    'blockchain.manualInbound': '退款补入账',
    'blockchain.manualInbound.table.head.txid': '原退币交易',
    'blockchain.manualInbound.table.label.assetCoin': '币种',
    'blockchain.manualInbound.table.head.custodyVisible': 'Custody 可见',
    'blockchain.manualInbound.table.head.watchOnly': 'WatchOnly 可见',
    'blockchain.manualInbound.table.head.orgId': 'Org ID',
    'blockchain.manualInbound.table.head.orgName': '组织名称',
    'blockchain.manualInbound.table.head.Result': '结果',
    'blockchain.manualInbound.table.head.withdraw_txid': '原提币交易',
    'blockchain.manualInbound.table.head.contractAddress': '合约地址',
    'blockchain.manualInbound.table.head.isActive': '是否生效',

    'blockchain.manualInbound.formItem.label.refundsAddress': '原退币地址',
    'blockchain.manualInbound.formItem.label.inboundAddress': '入帐地址',
    'blockchain.manualInbound.formItem.label.inboundAddressMemo': '入帐地址的memo',
    'blockchain.manualInbound.formItem.label.amount': '金额',
    'blockchain.manualInbound.formItem.label.phaTaskId': '工单',
    'blockchain.manualInbound.formItem.label.note': '备注',
    'blockchain.manualInbound.formItem.label.chainCoin': '币种',
    'blockchain.placeholder.addToken': '默认选择全部币种',
    'blockchain.modal.tokenInfoCheck.title': 'Token信息查询',
    'blockchain.modal.tokenInfoCheck.tokenAddress': '合约地址',
    'blockchain.modal.tokenInfoCheck.btn': 'Token地址检测',
    'blockchain.modal.tokenInfoCheck.error.title': '提示信息',
    'blockchain.modal.tokenInfoCheck.error.content': '查询Token信息失败',
    'blockchain.modal.tokenInfoCheck.tokenInfo': 'Token相关信息',

    'blockchain.form.required': '必填',
    'blockchain.form.btn.addToken': '添加Token',
    'blockchain.form.success.add': '添加成功',
    'blockchain.form.success.edit': '修改成功',
    'blockchain.form.coinRisk': '币种风险',
    'blockchain.form.coinRiskMark': '币种风险备注',

    'blockchain.logModal.expand.text': '点击左侧展开查看更多信息',
};
export default manualInbound;
