const coinManage = {
    'custody.coinManage.siderBar.title': 'Coin Manage',
    'custody.coinManage.table.header.chainCoin': 'Chain Coin',
    'custody.coinManage.table.header.fuzzyMatch': 'Fuzzy Match',
    'custody.coinManage.table.header.precision': 'Precision',
    'custody.coinManage.table.header.minimumDepositThreshold': 'Minimum Deposit Amount',
    'custody.coinManage.table.header.visible': 'Visible Range',
    'custody.coinManage.table.header.displayCode': 'Display Code',
    'custody.coinManage.table.header.confirmThreshold': 'Confirmation Threshold',
    'custody.coinManage.table.button.edit': 'Edit Visible Range',
    'custody.coinManage.table.header.coinRisk': 'Coin Risk',
    'custody.coinManage.placeholder.remark': 'Please enter remarks (1-50 characters).',
};

export default coinManage;
