import { lazy } from 'react';
const route = [
    {
        path: '/aladdin/self',
        component: lazy(() => import('../pages/aladdin_user/selfConponent')),
    },
    {
        path: '/custody',
        component: lazy(() => import('../pages/custody')),
    },
    {
        path: '/wallet',
        component: lazy(() => import('../pages/wallet_transaction')),
    },
    {
        path: '/blockchain',
        component: lazy(() => import('../pages/blockchain')),
    },
    {
        path: '/kyt',
        component: lazy(() => import('../pages/kyt')),
    },
    {
        path: '/riskControl',
        component: lazy(() => import('../pages/risk_control')),
    },
    {
        path: '/riskMenu',
        component: lazy(() => import('../pages/risk_menu')),
    },
    {
        path: '/position',
        component: lazy(() => import('../pages/position')),
    },
    {
        path: '/walletOperation',
        component: lazy(() => import('../pages/cobo_settings')),
    },
    {
        path: '/kyc',
        component: lazy(() => import('../pages/kycAudit')),
    },
    {
        path: '/bookkeeping',
        component: lazy(() => import('../pages/bookkeeping')),
    },
    {
        path: '/coboBonus',
        component: lazy(() => import('../pages/cobo_bonus')),
    },
    {
        path: '/admin',
        component: lazy(() => import('../pages/admin')),
    },
    {
        path: '/tools',
        component: lazy(() => import('../pages/coboTools')),
    },
    {
        path: '/approvalSystem',
        component: lazy(() => import('../pages/approvalSystem')),
    },
    {
        path: '/aladdin/404',
        component: lazy(() => import('../pages/404')),
    },
    {
        path: '/aladdin/',
        component: lazy(() => import('../pages/dashboard')),
    },
    {
        path: '/tss',
        component: lazy(() => import('../pages/tss/index.js')),
    },
    {
        path: '/argus',
        component: lazy(() => import('../pages/argus/index.js')),
    },
    {
        path: '/',
        component: lazy(() => import('../pages/dashboard')),
    },
];
export default route;
