const riskControlRuleManagement = {
    /**
     * 左侧菜单
     */
    'riskControl.ruleManagement': 'RC Rule Management',
    'riskControl.rulesDetail': 'RC Rule Detail',
    'riskControl.rulesUpload': 'RC Rule Upload',
    'riskControl.rulesVersions': 'RC Rule Versions',

    /**
     * 上线模式 通用文案
     */
    'riskControl.text.onlineMode': 'Online Mode',
    'riskControl.text.onlineMode.approvalOnline': 'Normal Online',
    'riskControl.text.onlineMode.emergencyOnline': 'Emergency online',

    /**
     * 组件：BPMN文件展示集成卡片组件
     */
    'riskControl.bpmn.bpmnIntegratedCard.diffPreview': 'Diff Preview',
    'riskControl.bpmn.bpmnIntegratedCard.format': 'BPMN DIAGRAM',
    'riskControl.bpmn.bpmnIntegratedCard.display': 'Display',
    'riskControl.bpmn.bpmnIntegratedCard.nodeProperties.title': 'Chosen Node properties as follows',

    /**
     * 规则列表页面
     */
    // tab
    'riskControl.ruleList.tab.title.online': 'online',
    'riskControl.ruleList.tab.title.pendingReview': 'Pending Review',
    'riskControl.ruleList.tab.button.AddBpmn': 'Add BPMN Rule',
    'riskControl.ruleList.tab.button.AddBusinessDefinition': 'Add Business Definition',
    // table
    'riskControl.ruleList.table.colomnTitle.index': 'Index',
    'riskControl.ruleList.table.colomnTitle.name': 'Name',
    'riskControl.ruleList.table.colomnTitle.version': 'Version',
    'riskControl.ruleList.table.colomnTitle.updateTime': 'Update Time',
    'riskControl.ruleList.table.colomnTitle.updatedBy': 'Updated by',
    'riskControl.ruleList.table.colomnTitle.createTime': 'Created Time',
    'riskControl.ruleList.table.colomnTitle.action': 'Action',
    // action links
    'riskControl.ruleList.table.actionLink.versionHistory': 'Version History',

    /**
     * 上传规则两步骤页面
     */
    // 步骤条 steps
    'riskControl.bpmnUpload.steps.one': 'Choose File to Upload',
    'riskControl.bpmnUpload.steps.two': 'Uploaded File Comfirm',
    // 步骤一
    'riskControl.bpmnUpload.stepsOne.form.fileName': 'File Name',
    'riskControl.bpmnUpload.stepsOne.form.chooseFile': 'File Choose',
    'riskControl.bpmnUpload.stepsOne.form.clickChoose': 'Choose File',
    'riskControl.bpmnUpload.stepsOne.form.next': 'Choose File',
    'riskControl.bpmnUpload.businessDefinitionIdList': ' Related Process Definition List',
    'riskControl.bpmnUpload.businessDefinitionIdList.tip': 'Note that updating the BMPN file will affect the list',
    // 步骤二
    'riskControl.bpmnUpload.stepsTwo.bpmnFile': 'BPMN File',
    'riskControl.bpmnUpload.stepsTwo.action.dataTest': 'Data Test',
    'riskControl.bpmnUpload.stepsTwo.selectOption.BpmnDiagram': 'BPMN DIAGRAME',
    'riskControl.bpmnUpload.stepsTwo.selectLabel.remark': 'Remark',

    /**
     * 规则详情页面
     */
    // 请求提示
    'riskControl.bpmnDetail.request.approval.official.comfirm': 'Do you Want to submit to agree the review ',
    'riskControl.bpmnDetail.request.approval.official.success': 'success to submit review ！',
    'riskControl.bpmnDetail.request.approval.gray.success': 'success to submit gray review ！',
    'riskControl.bpmnDetail.request.rejected.comfirm': 'Do you Want to reject this revision?',
    'riskControl.bpmnDetail.request.rejected.success': 'success to reject this revision！',
    'riskControl.bpmnDetail.request.revert.comfirm': 'Do you Want to revert to this BPMN revision?',
    'riskControl.bpmnDetail.request.revert.success': 'success to revert to this BPMN revision！',
    'riskControl.bpmnDetail.request.releaseAllFromGray.comfirm': 'Do you Want to official release all this version',
    'riskControl.bpmnDetail.request.releaseAllFromGray.success': 'success to official release all this version',
    'riskControl.bpmnDetail.request.stopGray.comfirm': 'Do you Want to stop this version in gray',
    'riskControl.bpmnDetail.request.stopGray.success': 'success to stop this version in gray！',
    'riskControl.bpmnDetail.request.enableGray.comfirm': 'Do you Want to enable this version in gray',
    'riskControl.bpmnDetail.request.enableGray.success': 'success to enable this version in gray！',
    // 页头详情取
    'riskControl.bpmnDetail.pageHeader.subtitle': 'detailed properties as follow',
    // action
    'riskControl.bpmnDetail.action.agree': 'Agree',
    'riskControl.bpmnDetail.action.reject': 'Reject',
    'riskControl.bpmnDetail.action.download': 'Download',
    'riskControl.bpmnDetail.action.dataTest': 'Data Test(Beta Phase)',
    'riskControl.bpmnDetail.action.revert': 'Revert To This Version',
    'riskControl.bpmnDetail.action.officialRelease': 'Official Release',
    'riskControl.bpmnDetail.action.stopGrayForNormal': 'Stop Gray For bNormal',
    'riskControl.bpmnDetail.action.stopGrayForAbnormal': 'Stop Gray For Abnormal',
    'riskControl.bpmnDetail.action.manageGrayList': 'Manage Gray List',
    'riskControl.bpmnDetail.action.enableGray': 'Enable Gray',
    // “配置管理灰度名单” Modal
    'riskControl.bpmnDetail.grayListConfigDialog.grayListLabel': 'Gray List',
    'riskControl.bpmnDetail.grayListConfigDialog.textarea.placeholder':
        'Please type org gray configs formatted with "org1@clientId1,clientId2;org2@clientId3"',
    'riskControl.bpmnDetail.grayListConfigDialog.title': 'Gray Edit Gray List Info',
    'riskControl.bpmnDetail.grayListConfigDialog.request.success': 'success to edit gray list！',
    // “审核通过信息确认” Modal
    'riskControl.bpmnDetail.agreeModal.title': 'Approval Information To Comfirm',
    'riskControl.bpmnDetail.agreeModal.releaseWay': 'Release Method',
    'riskControl.bpmnDetail.agreeModal.releaseWay.gray': 'Gray Release',
    'riskControl.bpmnDetail.agreeModal.releaseWay.all': 'Official Release',
    // dryrun Modal
    'riskControl.bpmnDetail.dataTestModal.title.instruction': 'Using Information',
    'riskControl.bpmnDetail.dataTestModal.process_key': 'Process Key',
    'riskControl.bpmnDetail.dataTestModal.value': 'Value',
    'riskControl.bpmnDetail.dataTestModal.org_id': 'Org ID',
    'riskControl.bpmnDetail.dataTestModal.account_id': 'Account ID',
    'riskControl.bpmnDetail.dataTestModal.user_id': 'User ID',
    'riskControl.bpmnDetail.dataTestModal.from_wallet_id': 'From Wallet ID',
    'riskControl.bpmnDetail.dataTestModal.from_wallet_name': 'From Wallet Name',
    'riskControl.bpmnDetail.dataTestModal.from_account_email_or_api': 'From Account Email Or Api',
    'riskControl.bpmnDetail.dataTestModal.source': 'Source',
    'riskControl.bpmnDetail.dataTestModal.request_id': 'Request ID',
    'riskControl.bpmnDetail.dataTestModal.coin_type': 'Coin Type',
    'riskControl.bpmnDetail.dataTestModal.to_address': 'To Address',
    'riskControl.bpmnDetail.dataTestModal.fee': 'Fee',
    'riskControl.bpmnDetail.dataTestModal.memo': 'Memo',
    'riskControl.bpmnDetail.dataTestModal.to_custody_wallet': 'To Custody Wallet',
    'riskControl.bpmnDetail.dataTestModal.template_version': 'Template Version',
    'riskControl.bpmnDetail.dataTestModal.ip': 'IP',
    'riskControl.bpmnDetail.dataTestModal.phone': 'Phone',
    'riskControl.bpmnDetail.dataTestModal.device_id': 'Device ID',
    'riskControl.bpmnDetail.dataTestModal.user_agent': 'User Agent',
    'riskControl.bpmnDetail.dataTestModal.request_created_time': 'Request Created Time',
    'riskControl.bpmnDetail.dataTestModal.kyt_high_risk_result': 'KYT High Risk Result',
    'riskControl.bpmnDetail.dataTestModal.batch_count': 'Batch Count',

    /**
     * BPMN Business Definition 列表
     */
    // tab title
    'riskControl.businessDefinition.tab.title': 'Process Definition',
    // table colomn
    'riskControl.businessDefinition.list.Table.colomnTitle.id': 'id',
    'riskControl.businessDefinition.list.Table.colomnTitle.processName': 'process_name',
    'riskControl.businessDefinition.list.Table.colomnTitle.processKey': 'process_key',
    'riskControl.businessDefinition.list.Table.colomnTitle.bpmnResourceIds': 'bpmn_resource_ids',
    'riskControl.businessDefinition.list.Table.colomnTitle.subscribeMethod': 'subscribe_method',
    'riskControl.businessDefinition.list.Table.colomnTitle.subscribeQueue': 'subscribe_queue',
    'riskControl.businessDefinition.list.Table.colomnTitle.createdTime': 'created_time',
    'riskControl.businessDefinition.list.Table.colomnTitle.modifiedTime': 'modified_time',
    'riskControl.businessDefinition.list.Table.colomnTitle.operatorName': 'operator_name',
    'riskControl.businessDefinition.list.Table.content.viewName': 'View Name',

    // 请求提示
    'riskControl.businessDefinition.list.request.updateProcessDefinition.success':
        'success to update the BPMN process definition',
    'riskControl.businessDefinition.list.request.createProcessDefinition.success':
        'success to create the BPMN process definition',
};

export default riskControlRuleManagement;
